import React, { Component } from 'react';
import BigCalendar from 'react-big-calendar'
import moment from 'moment'
import moment_timezone from 'moment-timezone';
moment.locale("en-GB");
BigCalendar.momentLocalizer(moment_timezone);
//BigCalendar.setLocalizer(BigCalendar.momentLocalizer(moment_timezone))
//dev
import Calendar from '../Calender';
import SwitchCheckbox from '../../reactcomponents/SwitchCheckbox';
import { FooterButton } from '../../reactcomponents/Admin';

//TODO(Mujibur): Need to find out why its not working?
//import 'react-big-calendar/lib/css/react-big-calendar.css';

Date.prototype.monthDays = function () {
    var d = new Date(this.getFullYear(), this.getMonth() + 1, 0);
    return d.getDate();
}
String.prototype.capitalize = function () {
    return this.charAt(0).toUpperCase() + this.slice(1);
}
class WorkingHours extends Component {
    constructor(props) {
        super(props);
        this.state = {
            "weekdays": {
                "Monday": {
                    working: true,
                    start: "00:00",
                    end: "23:59",
                    hasRule: false,
                    ruleChk: false
                },
                "Tuesday": {
                    working: true,
                    start: "00:00",
                    end: "23:59",
                    hasRule: false,
                    ruleChk: false
                },
                "Wednesday": {
                    working: true,
                    start: "00:00",
                    end: "23:59",
                    hasRule: false,
                    ruleChk: false
                },
                "Thursday": {
                    working: true,
                    start: "00:00",
                    end: "23:59",
                    hasRule: false,
                    ruleChk: false
                },
                "Friday": {
                    working: true,
                    start: "00:00",
                    end: "23:59",
                    hasRule: false,
                    ruleChk: false
                },
                "Saturday": {
                    working: false,
                    start: "00:00",
                    end: "23:59",
                    hasRule: false,
                    ruleChk: false
                },
                "Sunday": {
                    working: false,
                    start: "00:00",
                    end: "23:59",
                    hasRule: false,
                    ruleChk: false
                }
            },
            orgExceptions: [],
            events: [],
            exceptions: [],
            exceptionDate: "",			// yyyy-mm-dd
            exceptionDay: "",			// day of week in word e.g. "Monday"
            exceptionStart: "00:00",
            exceptionEnd: "23:59",
            exceptionDefault: true,
            exceptionWorking: true,
            modalOpen: false,
            posX: 0,
            posY: 0,
            updateDone: false,
            showBigCalendar: false
        }
        // default calendar's range (weekly)
        this.startRange = moment().startOf('week').toDate();
        this.endRange = moment().endOf('week').toDate();
        if (this.detectIE()) {
            // monthly view
            this.startRange = moment().startOf('month').toDate();
            this.endRange = moment().endOf('month').toDate();
        }
    }
    padZero = (num, len) => {
        let nstr = "0".repeat(len) + num;
        return nstr.substr(nstr.length - len);
    }
    formatTime = (hours, minutes) => {
        // hh:mm
        return this.padZero(hours, 2) + ":" + this.padZero(minutes, 2);
    }
    formatDate = (year, month, day, delimiter) => {
        // yyyy-mm-dd
        return year + delimiter + this.padZero(month, 2) + delimiter + this.padZero(day, 2);
    }
    buildExceptions = (exceptions) => {
        let wte = [];
        if (typeof exceptions !== 'undefined' && exceptions.length > 0) {
            exceptions.forEach(e => {
                let ymd = e.day.split("-");
                let year = parseInt(ymd[0], 10);
                let month = parseInt(ymd[1], 10);
                let day = parseInt(ymd[2], 10);
                let id = this.formatDate(year, month, day, '-');
                let startTime = this.formatTime(e.startHours, e.startMinutes);
                let endTime = this.formatTime(e.endHours, e.endMinutes);
                wte.push({
                    id: id,
                    title: `✔ ${startTime} - ${endTime}`,
                    start: new Date(year, month - 1, day, parseInt(e.startHours, 10), parseInt(e.startMinutes, 10)),
                    end: new Date(year, month - 1, day, parseInt(e.endHours, 10), parseInt(e.endMinutes, 10)),
                    working: e.working,
                    day: id,
                    startTime: startTime,
                    endTime: endTime
                });
            });
        }
        return wte;
    }
    //TODO(mujibur): this component needs a proper refactor
    UNSAFE_componentWillReceiveProps = (nextProps) => {
        if (nextProps.workingTimeRules != this.props.workingTimeRules) {
            let wr = nextProps.workingTimeRules;
            let orgExc = [];
            if (this.props.usedFor === 'area') {
                orgExc = this.buildExceptions(nextProps.orgWorkingTimeExceptions);
            }
            let wte = this.buildExceptions(nextProps.workingTimeExceptions);

            Object.keys(wr).forEach(k => {
                let day = k.capitalize();
                let rule = (this.props.usedFor === 'area' ? wr[k].hasRule : false);
                let workDay = wr[k];
                if (this.props.usedFor === 'area' && !rule) {
                    workDay = nextProps.orgWorkingTimeRules[k];
                }
                this.setState(prevState => ({
                    weekdays: {
                        ...prevState.weekdays,
                        [day]: {
                            ...prevState.weekdays[day],
                            working: workDay.working,
                            start: `${workDay.startHours}:${workDay.startMinutes}`,
                            end: `${workDay.endHours}:${workDay.endMinutes}`,
                            hasRule: rule,
                            ruleChk: !rule
                        }
                    }
                }));
            });
            this.setState({
                exceptions: wte,
                orgExceptions: orgExc
            }, () => {
                this.coloredDate();
            });
        }
    }
    updateCalendarEvent = (pre, year, month, day) => {
        let today = new Date(year, month - 1, day);
        let weekDays = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
        let dayOfWeek = weekDays[today.getDay()];
        let workDay = this.state.weekdays[dayOfWeek];
        let start = workDay.start.split(":");
        let end = workDay.end.split(":");
        let id = this.formatDate(year, month, day, "-");
        let exception = $.grep(this.state.exceptions, function (obj) { return obj.id === id })[0];

        pre = pre.filter(obj => obj.id !== id);
        if (typeof exception !== 'undefined') {
            if (exception.working) {
                pre.push(exception);
            }
            return pre;
        }
        let workingDay = {
            id: id,
            title: `✔ ${workDay.start} - ${workDay.end}`,
            start: new Date(year, month - 1, day, start[0], start[1]),
            end: new Date(year, month - 1, day, end[0], end[1]),
            working: workDay.working,
        };
        if (workDay.working) {
            pre.push(workingDay);
        }

        if (this.props.usedFor === 'area') {
            if (workDay.hasRule) {
                return pre;
            }
            exception = $.grep(this.state.orgExceptions, function (obj) { return obj.id === id })[0];
            if (typeof exception !== 'undefined') {
                pre = pre.filter(obj => obj.id !== id);
                if (exception.working) {
                    pre.push(exception);
                }
            }
        }
        return pre;
    }
    coloredDate = () => {
        this.setState({ updateDone: true }, () => {
            let pre = this.state.events;
            for (let d = new Date(this.startRange.getTime()); d <= this.endRange; d.setDate(d.getDate() + 1)) {
                pre = this.updateCalendarEvent(pre, d.getFullYear(), d.getMonth() + 1, d.getDate());
            }
            this.setState({ events: pre });
        });
    }
    handleOnRuleChange = (e) => {
        let target = e.target.name;
        let day = target.split('_')[0];
        let value = e.target.checked;
        this.setState(prevState => ({
            weekdays: {
                ...prevState.weekdays,
                [day]: {
                    ...prevState.weekdays[day],
                    hasRule: !value,
                    ruleChk: value
                }
            },
            events: [],
        }), () => {
            this.coloredDate();
        });
    }
    handleSwitchBoxChange = (checked, state) => {
        let value = !checked;
        let day = state.split('_')[0];
        this.setState(prevState => ({
            weekdays: {
                ...prevState.weekdays,
                [day]: {
                    ...prevState.weekdays[day],
                    hasRule: !value,
                    ruleChk: value
                }
            },
            events: [],
        }), () => {
            this.coloredDate();
        });
    }
    handleOnChange = (day, checked) => {
        let value = !checked;
        this.setState(prevState => ({
            weekdays: {
                ...prevState.weekdays,
                [day]: {
                    ...prevState.weekdays[day],
                    working: value
                }
            },
            events: []
        }), () => {
            this.coloredDate();
        });
    }
    handleStartChange = (date, id) => {
        let day = id.split('_')[0];
        this.setState(prevState => ({
            weekdays: {
                ...prevState.weekdays,
                [day]: {
                    ...prevState.weekdays[day],
                    start: date
                }
            },
            events: []
        }), () => {
            this.coloredDate();
        });
    }
    handleEndChange = (date, id) => {
        let day = id.split('_')[0];
        this.setState(prevState => ({
            weekdays: {
                ...prevState.weekdays,
                [day]: {
                    ...prevState.weekdays[day],
                    end: date
                }
            },
            events: []
        }), () => {
            this.coloredDate();
        });
    }
    dayStartCalendar = (working, day, start) => {
        if (this.props.usedFor === 'area') {
            if (!this.state.weekdays[day].hasRule) {
                if (typeof this.props.orgWorkingTimeRules !== "undefined") {
                    let lDay = day.toLowerCase();
                    start = this.props.orgWorkingTimeRules[lDay].startHours +
                        ":" + this.props.orgWorkingTimeRules[lDay].startMinutes;
                    return (<td>{start}</td>);
                } else {
                    return (<td></td>);
                }
            }
        }
        return (<td>{working && <Calendar id={day + "_start_" + this.props.updateRulesField} type="linkNoDel" timeFormat=":" timepicker={true} selectedDate={start} onChange={this.handleStartChange} />}</td>)
    }
    dayEndCalendar = (working, day, end) => {
        if (this.props.usedFor === 'area') {
            if (!this.state.weekdays[day].hasRule) {
                if (typeof this.props.orgWorkingTimeRules !== "undefined") {
                    let lDay = day.toLowerCase();
                    end = this.props.orgWorkingTimeRules[lDay].endHours +
                        ":" + this.props.orgWorkingTimeRules[lDay].endMinutes;
                    return (<td>{end}</td>);
                } else {
                    return (<td></td>);
                }
            }
        }
        return (<td>{working && <Calendar id={day + "_end_" + this.props.updateRulesField} type="linkNoDel" timeFormat=":" timepicker={true} selectedDate={end} onChange={this.handleEndChange} />}</td>);
    }
    handleCustomizeClick = () => {
        let show = !this.state.showBigCalendar;
        this.setState({
            showBigCalendar: show
        });
    }
    getButtonElement = () => {
        let collection = document.getElementsByClassName("rbc-toolbar");
        if (collection[0] !== undefined) {
            let buttonGroup = collection[0].firstChild.children;
            let buttonGroup2 = collection[0].lastChild.children;
            let spanElement = document.createElement("span")
            let iElement = document.createElement("i");
            iElement.className = 'icon-calendar-plus';
            spanElement.append(iElement);
            buttonGroup[0].innerText = "";
            buttonGroup[1].innerText = "<";
            buttonGroup[2].innerText = ">";
            buttonGroup[0].setAttribute("id", 'button-1');
            buttonGroup[1].setAttribute("id", 'button-2');
            buttonGroup[2].setAttribute("id", 'button-3');
            buttonGroup[0].append(spanElement);
            buttonGroup2[0].setAttribute("id", 'button2-1');
            buttonGroup2[1].setAttribute("id", 'button2-2');
        }
        if (collection[1] !== undefined) {
            let buttonGroup = collection[1].firstChild.children;
            let buttonGroup2 = collection[1].lastChild.children;
            let spanElement = document.createElement("span")
            let iElement = document.createElement("i");
            iElement.className = 'icon-calendar-plus';
            spanElement.append(iElement);
            buttonGroup[0].innerText = "";
            buttonGroup[1].innerText = "<";
            buttonGroup[2].innerText = ">";
            buttonGroup[0].setAttribute("id", 'button-1');
            buttonGroup[1].setAttribute("id", 'button-2');
            buttonGroup[2].setAttribute("id", 'button-3');
            buttonGroup[0].append(spanElement);
            buttonGroup2[0].setAttribute("id", 'button2-1');
            buttonGroup2[1].setAttribute("id", 'button2-2');
        }
    }
    render = () => {
        this.getButtonElement();
        let weekdays = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
        let showBigCalendar = this.state.showBigCalendar;
        let rightBarDisplay = { display: "none" };
        if (showBigCalendar) {
            rightBarDisplay = { display: "block" };
        }
        let weekdayRender = weekdays.map((day, i) =>
            <tr key={this.props.updateRulesField + i}>
                {this.props.usedFor === "area" &&
                    <td>
                        <SwitchCheckbox
                            active={this.state.weekdays[day].ruleChk}
                            buttonClassName={""}
                            id={"orgActiveSwitch"}
                            name={day + "_working"}
                            type="checkbox"
                            onClick={() => this.handleSwitchBoxChange(this.state.weekdays[day].ruleChk, day + "_rule")}
                        />
                    </td>}
                <th scope="row">{day}</th>
                {this.dayStartCalendar(this.state.weekdays[day].working, day, this.state.weekdays[day].start)}
                {this.dayEndCalendar(this.state.weekdays[day].working, day, this.state.weekdays[day].end)}
                <td>
                    <SwitchCheckbox
                        active={this.state.weekdays[day].working}
                        buttonClassName={""}
                        id={"orgActiveSwitch"}
                        name={day + "_working"}
                        type="checkbox"
                        onClick={() => this.handleOnChange(day, this.state.weekdays[day].working)}
                        disabled={((this.props.usedFor === 'area' && this.state.weekdays[day].ruleChk) ? true : false)}
                    />
                </td>
                {/* <td><input type="checkbox" name={day + "_working"} onChange={this.handleOnChange} checked={this.state.weekdays[day].working} disabled={((this.props.usedFor === 'area' && this.state.weekdays[day].ruleChk) ? true : false)} /></td> */}
            </tr>
        );
        return (<div className="working-hours">
            <div className="leftside-bar">
                <table className="table table-inverse">
                    <thead>
                        <tr>
                            {this.props.usedFor === "area" && <th style={{ width: '12%' }}>{I("Organization setting?")}</th>}
                            <th>{I("Day")}</th>
                            <th>{I("Start time")}</th>
                            <th>{I("End time")}</th>
                            <th>{I("Working?")}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {weekdayRender}
                    </tbody>
                </table>
                <div className="form-group editPageActionButton">
                    <div className="row">
                        <div className="col-lg-6 textAlignLeft">
                            <span className="setting-icons" style={{ cursor: "pointer" }} onClick={() => { this.handleCustomizeClick(); }}>
                                <i className="icon-settings"></i>
                                <div className="setting-text">Customize</div>
                            </span>
                        </div>
                        <div className="col-lg-6 textAlignRight">
                            <FooterButton cancel={this.props.cancelToOrg} save={this.saveOrg} />
                        </div>
                    </div>
                </div>

            </div>
            <div className="rightside-bar" style={rightBarDisplay}>
                <BigCalendar
                    selectable
                    views={!this.detectIE() ? ["month", "week"] : ["month"]}
                    events={this.state.events}
                    defaultView={!this.detectIE() ? "week" : "month"}
                    step={60}
                    scrollToTime={new Date(2015, 1, 1)}
                    defaultDate={new Date()}
                    onSelectEvent={this.handleOnSelect}
                    onSelectSlot={this.handleSlot}
                    onRangeChange={this.handleChangeRange}
                    drilldownView={""}
                />
                {this.state.modalOpen && <Modal posX={this.state.posX} posY={this.state.posY} show={this.state.modalOpen} onClose={this.toggleModal}>
                    <table className="table table-inverse working-hour-table">
                        <tbody>
                            <tr className="table-header"><td colSpan="2">{this.state.exceptionDate}</td></tr>
                            <tr>
                                <td colSpan="2">
                                    {I('Use default setting')}
                                    <SwitchCheckbox
                                        active={this.state.exceptionDefault}
                                        buttonClassName={""}
                                        id={"orgActiveSwitch"}
                                        name={"_working"}
                                        type="checkbox"
                                        onClick={() => this.handleOnExDefaultSetting(this.state.exceptionDefault)}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td colSpan="2">
                                    {I("Working?")}
                                    <SwitchCheckbox
                                        active={this.state.exceptionWorking}
                                        buttonClassName={""}
                                        id={"orgActiveSwitch"}
                                        name={"_working"}
                                        type="checkbox"
                                        onClick={() => this.handleOnExWorking(this.state.exceptionWorking)}
                                    /></td>
                            </tr>
                            <tr>
                                <td>{I("Start time")}
                                {!this.state.exceptionDefault && this.state.exceptionWorking && <Calendar id={"start_except"} type="linkNoDel" timeFormat=":" timepicker={true} selectedDate={this.state.exceptionStart} onChange={this.handleOnExceptionStart} />}
                                </td>
                            </tr>
                            <tr>
                                <td>{I("End time")}
                                {!this.state.exceptionDefault && this.state.exceptionWorking && <Calendar id={"end_except"} type="linkNoDel" timeFormat=":" timepicker={true} selectedDate={this.state.exceptionEnd} onChange={this.handleOnExceptionEnd} />}
                                </td>
                            </tr>
                            <tr>
                                <FooterButton cancel={this.toggleModal} save={this.handleSaveException} />
                            </tr>
                        </tbody>
                    </table>
                </Modal>
                }
            </div>
        </div>)
    }
    detectIE = () => {
        return false || !!document.documentMode;
    }
    saveException = () => {
        let ds = {}
        ds[this.props.updateExceptionsField] = true;
        ds.workingTimeExceptions = JSON.stringify(this.state.exceptions);
        this.props.saveWorkingHourExcp(ds);
    }
    handleSaveException = (event) => {
        let selectedDate = this.state.exceptionDate.split("-");
        let year = parseInt(selectedDate[0], 10), month = parseInt(selectedDate[1], 10), day = parseInt(selectedDate[2], 10);
        let id = this.formatDate(year, month, day, '-');
        let exp = this.state.exceptions.filter(obj => obj.id !== id);

        if (this.state.exceptionDefault == false) {
            let startTime = this.state.exceptionStart;
            let endTime = this.state.exceptionEnd;
            let start = startTime.split(":");
            let end = endTime.split(":");
            let exception = {
                id: id,
                title: `✔ ${startTime} - ${endTime}`,
                start: new Date(year, month - 1, day, start[0], start[1]),
                end: new Date(year, month - 1, day, end[0], end[1]),
                working: this.state.exceptionWorking,
                day: id,
                startTime: startTime,
                endTime: endTime
            };
            exp.push(exception);
        }
        this.setState({ exceptions: exp }, () => {
            this.toggleModal();
            this.saveException();

            let pre = this.updateCalendarEvent(this.state.events, year, month, day);
            this.setState({ events: pre });
        });
    }
    saveOrg = () => {
        var dataObj = {};
        dataObj[this.props.updateRulesField] = true
        dataObj.workingTimeData = JSON.stringify(this.state.weekdays);
        this.props.saveOrganization(WORKINGHOUR, dataObj);
    }
    handleSlot = (slotInfo) => {
        if (slotInfo.action === "select") {
            this.setState({ updateDone: true }, () => {
                let sd = new Date(`${slotInfo.start}`);
                let ed = new Date(`${slotInfo.end}`);
                let year = sd.getFullYear(), month = sd.getMonth(), day = sd.getDate();
                if (sd == ed || typeof slotInfo.bounds == "undefined") {
                    sd = new Date(year, month, day, 0, 0);
                    ed = new Date(year, month, day, 23, 59);
                }
                let shour = sd.getHours(), smin = sd.getMinutes();
                let ehour = ed.getHours(), emin = ed.getMinutes();
                let id = this.formatDate(year, month + 1, day, "-");
                let startTime = this.formatTime(shour, smin);
                let endTime = this.formatTime(ehour, emin);

                let exception = {
                    id: id,
                    title: `✔ ${startTime} - ${endTime}`,
                    start: sd,
                    end: ed,
                    working: true,
                    day: id,
                    startTime: startTime,
                    endTime: endTime
                };
                let exp = this.state.exceptions.filter(obj => obj.id !== id);
                exp.push(exception);

                let pre = this.state.events.filter(obj => obj.id !== id);
                pre.push(exception);

                this.setState({ events: pre, exceptions: exp }, () => {
                    this.saveException();
                });
            });
        } else if (slotInfo.action === "click") {
            if (typeof slotInfo.box != "undefined") {
                this.showExceptionDialog(slotInfo.start, slotInfo.box.x - ($('.adminWrapper').width() / 2) - 180, slotInfo.box.y - 50);
            }
        }
    }
    handleChangeRange = (r) => {
        if (Array.isArray(r)) {
            this.startRange = r[0];
            this.endRange = r[r.length - 1];
        } else {
            this.startRange = r.start;
            this.endRange = r.end;
        }
        this.setState({
            events: [],
        }, () => {
            this.coloredDate();
        });
    }
    showExceptionDialog = (selectedDate, xpos, ypos) => {
        let posX = xpos - 160;     
        let posY = ypos;   
        let today = new Date(selectedDate);
        let year = today.getFullYear(), month = today.getMonth(), day = today.getDate()
        let weekDay = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
        let dayOfWeek = weekDay[today.getDay()];
        let useDefault, working;
        let startTime = "00:00";
        let endTime = "23:59";
        let id = this.formatDate(year, month + 1, day, "-");

        let exception = $.grep(this.state.exceptions, function (obj) { return obj.id === id })[0];
        if (typeof exception !== 'undefined') {
            startTime = exception.startTime;
            endTime = exception.endTime;
            useDefault = false;
            working = exception.working;
        } else {
            let workDay = this.state.weekdays[dayOfWeek];
            if (workDay.working) {
                startTime = this.formatTime(workDay.startHours, workDay.startMinutes);
                endTime = this.formatTime(workDay.endHours, workDay.endMinutes);
            }
            useDefault = true;
            working = workDay.working;
        }
        this.setState({
            exceptionDate: id,
            exceptionDay: dayOfWeek,
            exceptionStart: startTime,
            exceptionEnd: endTime,
            modalOpen: true,
            exceptionDefault: useDefault,
            exceptionWorking: working,
            posX: posX,
            posY: posY
        });
    }
    handleOnSelect = (ev, e) => {
        this.showExceptionDialog(ev.start, e.pageX - ($('.adminWrapper').width() / 2) - 180, e.pageY - 50);
    }
    handleOnExDefaultSetting = (checked) => {
        let useDefault = !checked;
        let selectedDate = this.state.exceptionDate.split("-");
        let year = parseInt(selectedDate[0], 10), month = parseInt(selectedDate[1], 10), day = parseInt(selectedDate[2], 10);
        let weekDay = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
        let today = new Date(year, month - 1, day);
        let dayOfWeek = weekDay[today.getDay()];
        let working;
        let startTime = "00:00";
        let endTime = "23:59";
        let id = this.formatDate(year, month, day, "-");

        if (useDefault == true) {
            let workDay = this.state.weekdays[dayOfWeek];
            if (workDay.working) {
                startTime = workDay.start;
                endTime = workDay.end;
            }
            working = workDay.working;
        } else {
            let exception = $.grep(this.state.exceptions, function (obj) { return obj.id === id })[0];
            if (typeof exception !== 'undefined') {
                startTime = exception.startTime;
                endTime = exception.endTime;
                working = exception.working;
            } else {
                let workDay = this.state.weekdays[dayOfWeek];
                if (workDay.working) {
                    startTime = workDay.start;
                    endTime = workDay.end;
                }
                working = workDay.working;
            }
        }
        this.setState({
            exceptionDefault: useDefault,
            exceptionWorking: working,
            exceptionStart: startTime,
            exceptionEnd: endTime,
        });
    }
    handleOnExWorking = (checked) => {
        let working = !checked;
        if (!this.state.exceptionDefault) {
            this.setState({ exceptionWorking: working });
        }
    }
    handleOnExceptionStart = (startTime, id) => {
        this.setState({ exceptionStart: startTime });
    }
    handleOnExceptionEnd = (endTime, id) => {
        this.setState({ exceptionEnd: endTime });
    }
    toggleModal = () => {
        const state = this.state.modalOpen;
        this.setState({ modalOpen: !state });
    }
}
export default WorkingHours;

class Modal extends React.Component {
    render() {
        const { posX, posY } = this.props;
        // Custom styles: set visibility and backbround color
        const styles = {
            modal: {
                top: posY + 350,
                left: posX - 75
            }
        };
        return (
            <div className="modal-wrapper" style={styles.modal}>
                <span className="glyphicon glyphicon-remove-sign modal-item" onClick={this.props.onClose}></span>
                <div className="modal-item">
                    {this.props.children}
                </div>
            </div>
        )
    }
}
