import React from "react";
import memoizeOne from 'memoize-one';
import { I } from '../../common/v5/config.js';
import { withUnmountWhenHidden } from '../../reactcomponents/hocs';
import { AreaDropdown, ActionDropdown } from '../../reactcomponents/Dropdown';
import Calendar from '../../reactcomponents/DatePicker';
import { HotkeyLabel } from '../../reactcomponents/Hotkeys';
import SidebarSettingsList from "../../reactcomponents/SidebarSettingsList";
import { ActionModalPrintCtnr } from '../../containers/print';
import {
	getChevronIcon
	, serializeArrayOfKeys
	, serializeUniqueValueOfArrayOfKeys
	, workflowHotkeyChar
} from '../../common/v5/helpers';
import {
	FWD_TO_AGENT_STR,
	MY_ERRANDS,
	NEW_ERRANDS,
	POSTPONED_ERRANDS,
	WORKFLOW_HOTKEYS,
	DELETE_SELECTED,
	CLOSE_SELECTED,
	RETURN_ERRAND,
	SEARCH_ERRANDS,
	SEND_ERRAND,
	MESSAGE_MUST_SELECT_MORE_THAN_ONE_ERRAND
} from '../../common/v5/constants';

const WrapBase = props => <li {...props} />;

const Wrap = withUnmountWhenHidden(WrapBase);

const WrapDarkGrey = ({customClass, ...props}) => <Wrap className={"btn-dark-grey"+(customClass?customClass:"")} {...props} />;

const WrapRed = ({customClass, ...props}) => <Wrap className={"btn-red"+(customClass?customClass:"")} {...props} />;

const WrapWhite = ({customClass, ...props}) => <Wrap className={"btn-white"+(customClass?customClass:"")} {...props} />;

const WrapBlue = ({customClass, ...props}) => <Wrap className={"btn-blue"+(customClass?customClass:"")} {...props} />;

const IconBase = ({ className, children }) => (
	<span><i className={className} />{children}</span>
);

const Icon = ({ className, text }) => {
	if (text) {
		text = " " + text;
	}
	return <IconBase className={className}>{text}</IconBase>;
};

export const IconAndChevron = ({ iconClassName, up }) => (
	<IconBase className={iconClassName}>
		<i className={getChevronIcon(up)} />
	</IconBase>
);

const iconArrowWithMember = "icon-forward-agent";

export const IconMemberAndChevron = ({ up }) => <IconAndChevron iconClassName={iconArrowWithMember} up={up} />;

const IconMember = ({ text }) => <Icon className={iconArrowWithMember} text={text} />;

function forwardToAgentOrTeamLeader(allowAgentForwardToAgent) {
	if (allowAgentForwardToAgent) {
		return FWD_TO_AGENT_STR;
	}
	return I("Forward To Team Leader");
}

// NOTE: share forward header text because both forward errands and open errand
// to agent will be affected by the same feature of allowing agent to agent
// forward. The memoized should be removed if both don't share same feature.
export const memoizedForwardAgentText = memoizeOne(forwardToAgentOrTeamLeader);

export const ForwardToAgentDropdown = ({
	allowAgentForwardToAgent
	, iconOnly
	, show
	, ...props
}) => {
	const headerText = memoizedForwardAgentText(allowAgentForwardToAgent);
	let title;
	if (iconOnly) {
		title = <IconMemberAndChevron up={show} />;
	} else {
		title = <IconMember text={headerText} />;
	}
	return (
		<ActionDropdown
			isHeader={true}
			headerText={L(headerText)}
			show={show}
			title={L(title)}
			menuClassName={"header-action-toolbar"}
			{...props}
		/>
	);
};

class ForwardErrandsToAgent extends React.PureComponent {
	constructor(props) {
		super(props);
		this.handleDropdownToggle = this.handleDropdownToggle.bind(this);
		this.handleClick = this.handleClick.bind(this);
		this.state = {
			show: false
		};
	}
	componentDidUpdate(prevProps, prevState) {
		const { show } = this.state;
		if (show
			&& show !== prevState.show
			&& typeof this.props.onOpen === "function") {
			this.props.onOpen();
		}
	}
	handleToggle(state) {
		if(!this.props.disabled) {
			this.setState({show: !state});
		}
	}
	handleClick(e) {
		this.handleToggle(this.state.show);
	}
	handleDropdownToggle (id, state) {
		this.handleToggle(state);
	}
	render() {
		const { hidden, onSelect, ...props } = this.props;
		return (
			<WrapDarkGrey
				data-qa-id="wf-action-forward-agent"
				hidden={hidden}
				onClick={this.handleClick}
				customClass={this.props.agentWorking?" disabled":""}
			>
				<ForwardToAgentDropdown
					show={this.state.show}
					onSelectItem={onSelect}
					onToggle={this.handleDropdownToggle}
					{...props}
				/>
			</WrapDarkGrey>
		);
	}
}

export default class WorkflowActions extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			selectedWFDueDateUI : null
		};
		this.handleActionToggling = this.handleActionToggling.bind(this);
		this.handleOpenForwardToAgent = this.handleOpenForwardToAgent.bind(this);
		this.handleSelectForwardToAgent = this.handleSelectForwardToAgent.bind(this);
		this.onSelectFolder = this.onSelectFolder.bind(this);
		this.onSelectArea = this.onSelectArea.bind(this);
		this.onPrintErrands = this.onPrintErrands.bind(this);
		this.handlePrintAction = this.handlePrintAction.bind(this);
		this.printIt = this.printIt.bind(this);
		this.onDeleteErrands = this.onDeleteErrands.bind(this);
		this.onCloseErrands = this.onCloseErrands.bind(this);
		this.onAcquireErrands = this.onAcquireErrands.bind(this);
		this.onSetPriority = this.onSetPriority.bind(this);
		this.onSetDueDate = this.onSetDueDate.bind(this);
		this.handleClearDueDate = this.handleClearDueDate.bind(this);
		this.handleUpdateDueDate = this.handleUpdateDueDate.bind(this);
		this.handleReturnToInbox = this.handleReturnToInbox.bind(this);
		this.handleSendingBulkErrand = this.handleSendingBulkErrand.bind(this);
		this.handleLinkErrand = this.handleLinkErrand.bind(this);
		this.handleAcquireFromOtherAgentErrand = this.handleAcquireFromOtherAgentErrand.bind(this);
	}
	handleActionToggling(id, action){
		if (!this.props.agentWorking) {
			this.props.onHandleMultipleErrandAction(id, !action);
		}
	}
	getSerializedIdAndCipherKeyFromProps() {
		const { selectedErrands } = this.props;
		if (!selectedErrands || !selectedErrands.length) {
			return ["", ""];
		}
		return serializeArrayOfKeys(selectedErrands, "id", "cipherKey");
	}
	getSerializedFieldFromProps(field, checkUniqueness) {
		const { selectedErrands } = this.props;
		if (!selectedErrands || !selectedErrands.length) {
			return "";
		}
		let serializer;
		if (!checkUniqueness) {
			serializer = serializeArrayOfKeys;
		} else {
			serializer = serializeUniqueValueOfArrayOfKeys;
		}
		const [ serialized ] = serializer(selectedErrands, field);
		return serialized;
	}
	getSerializedIdFromProps() {
		return this.getSerializedFieldFromProps("id");
	}
	handleOpenForwardToAgent() {
		if(!this.props.agentWorking) {
			this.props.onOpenForwardToAgent(
				this.getSerializedFieldFromProps("area", true));
		}
	}
	handleSelectForwardToAgent(agentId) {
		const [ ids, cipherKeys ] = this.getSerializedIdAndCipherKeyFromProps();
		this.props.onSelectForwardToAgent(agentId, ids, cipherKeys);
	}
	onSelectFolder(fid) {
		const [ ids, cipherKeys ] = this.getSerializedIdAndCipherKeyFromProps();
		this.props.onHandleMoveToFolder({
			list: ids
			, cipher_keys: cipherKeys
			, folder: fid
		});
	}
	renderSelectToMoveFolder(){
		const p = this.props;
		if(p.currentContext == MY_ERRANDS || p.agentCanMoveFromInboxFeature) {
			return(
				<WrapDarkGrey data-qa-id="wf-action-move-folder" customClass={p.agentWorking?" disabled":""}>
					<ActionDropdown id="moveFolder"
						title={
							<Icon
								className="icon-folder"
								text={I("Move to folder")}
							/>
						}
						show={p.showMoveToFolder}
						menuClassName={"header-action-toolbar"}
						isHeader={true}
						headerText={I("Move to folder")}
						data={p.userFolders}
						onToggle={this.handleActionToggling}
						onSelectItem={this.onSelectFolder}
					/>
				</WrapDarkGrey>
			)
		}
	}
	onSelectArea(areaId) {
		const [ ids, cipherKeys ] = this.getSerializedIdAndCipherKeyFromProps();
		this.props.onHandleForwardToArea(ids, cipherKeys, areaId);
	}
	onPrintErrands() {
		const ids = this.getSerializedIdFromProps();
		this.props.onHandlePrintErrands(ids, !this.props.shouldPopPrint);
		setTimeout(this.handlePrintAction, 1000);
	}
	handlePrintAction(){
		if(this.props.shouldPopPrint){
			let contentToPrint = document.getElementById("printableArea").cloneNode(true);
			let sectionToPrint = document.getElementById("section-to-print");
			if (!sectionToPrint) {
				sectionToPrint = document.createElement("div");
				sectionToPrint.id = "section-to-print";
				document.body.appendChild(sectionToPrint);
			}
			sectionToPrint.innerHTML = "";
			sectionToPrint.appendChild(contentToPrint);

			$('head').append('<style type="text/css">@media print {#container, #printErrand, .modal-header, .modal-footer{display: none;}}}</style>');
			window.print();
			setTimeout(()=>{
				$('head').append('<style type="text/css">@media print {#container, #printErrand, .modal-header, .modal-footer {display: block;}}}</style>');
				sectionToPrint.innerHTML = "";
			}, 100);
		}
	}
	printIt(){
		this.handlePrintAction();
	}
	renderSelectToForward(){
		const p = this.props;
		if(p.agentCanForwardToAreaFeature) {
			let areaOptions = "";
			let forwardToArea = p.forwardToAreas;
			let agentCanForwardToAreaInOrganization = p.agentCanForwardToAreaInOrganizationFeature;
			areaOptions = (
				<AreaDropdown
					menuClassName={"header-action-toolbar"}
					title={
						<Icon
							className="icon-arrow-right"
							text={I("Forward to area")}
						/>
					}
					id="forwardArea"
					show={p.showForwardAreaOptions}
					data={forwardToArea}
					isHeader={true}
					nested={{key: 'Areas'}}
					headerText={I("Forward to area")}
					idFields={{id: "Id", name: "Name"}}
					onToggle={this.handleActionToggling}
					onSelect={this.onSelectArea}
				/>
			);
			return (
				<WrapDarkGrey data-qa-id="wf-action-forward-area" customClass={p.agentWorking?" disabled":""}>
					{areaOptions}
				</WrapDarkGrey>
			);
		}
	}
	onDeleteErrands() {
		if(!this.props.agentWorking){
			this.props.onHandleDelete(this.getSerializedIdFromProps());
		}
	}
	onCloseErrands() {
		if(!this.props.agentWorking){
			this.props.onHandleClose(this.getSerializedIdFromProps());
		}
	}
	onAcquireErrands() {
		if(!this.props.agentWorking){
			if(this.props.selectedAgent !== 0) {
				this.handleAcquireFromOtherAgentErrand();
			} else {
				this.onSelectFolder(this.props.defaultFolderId)
			}
		}
	}
	onSetPriority() {
		if(!this.props.agentWorking){
			const [ ids, cipherKeys ] = this.getSerializedIdAndCipherKeyFromProps();
			this.props.onHandleSetPriority(ids, cipherKeys);
		}
	}
	handleUpdateDueDate(date){
		const [ ids, cipherKeys ] = this.getSerializedIdAndCipherKeyFromProps();
		let duedate = this.state.selectedWFDueDateUI.format("YYYY/MM/DD");
		let reloadId = 0;
		if(ids != ""){
			let arr = ids.split(",");
			for(let i=0;i < arr.length; i++){
				if(arr[i] == this.props.currentOpened){
					reloadId = this.props.currentOpened;
					break;
				}
			}
		}
		this.props.onHandleSetDueDate(ids, cipherKeys, duedate, reloadId);
	}
	handleClearDueDate(){
		this.setState({"selectedWFDueDateUI": null});
	}
	renderDueDateOption(){
		if(this.props.dueDateFeature && this.props.allowDueDate) {
			return (
				<div>
					<Calendar
						ref={"setErrandsDueDateCal"}
						data-qa-id="wf-action-due-date"
						button={true} customClass={"btn-yellow" + (this.props.agentWorking ? " disabled": "")}
						onChange={this.onSetDueDate}
						defaultValue={I("Add due date")}
						position={"bottomLeft"}
						handleCustomChange={this.handleUpdateDueDate}
						onReset={this.handleClearDueDate}
						selected={this.state.selectedWFDueDateUI}
						disabled={this.props.agentWorking}
					>
						<a
							hidden={this.state.selectedWFDueDateUI ? false : true}
							className="customUpdateButton"
						>
							{I("Add due date")}
						</a>
					</Calendar>
				</div>
			);
		}
	}
	onSetDueDate(dueDate){
		this.setState({selectedWFDueDateUI: dueDate});
	}
	handleReturnToInbox() {
		if(!this.props.agentWorking){
			this.props.onHandleReturnToInbox(this.getSerializedIdFromProps());
		}
	}
	handleLinkErrand() {
		if (this.props.totalSelected <= 1) {
			alert (MESSAGE_MUST_SELECT_MORE_THAN_ONE_ERRAND)
		} else {
			if(!this.props.agentWorking){
				// this.props.onHandleReturnToInbox(this.getSerializedIdFromProps());
				this.props.onHandleLinkErrand(this.getSerializedIdFromProps());
			}
		}
	}
	handleAcquireFromOtherAgentErrand(){
		const [ids] = this.getSerializedIdAndCipherKeyFromProps()
			, isAcquireFromOtherAgentErrand = true
			, isErrandView = false;
		this.props.handleAcquireFromOtherAgentErrand(
			ids
			, isAcquireFromOtherAgentErrand
			, isErrandView
		)
	}
	hasEmailService(){
		for(let i=0;i < this.props.selectedErrands.length; i++){
			if(this.props.selectedErrands[i].service ==
				Workflow.Errand.SERVICE_EMAIL ||
				this.props.selectedErrands[i].service ==
				Workflow.Errand.SERVICE_MANUAL){
				return true;
			}
		}
		return false;
	}
	handleSendingBulkErrand() {
		if(this.props.totalSelected >= 1 && !this.props.agentWorking) {
			this.props.onHandleSendingBulkErrand(this.getSerializedIdFromProps());
		}
	}
	render() {
		const {
				allowAgentForwardToAgent
				, allowForwardErrandsToAgent
				, allowCloseErrand
				, forwardErrandsAgents
				, hasClosedErrand
				, hasPendingReviewErrand
				, isLoadingFwdErrandsAgents
				, defaultFolderId
				, ...p
			} = this.props
			, closed = hasClosedErrand || hasPendingReviewErrand
			;
		let errandText;
		// TODO: this seem wrong as how can we sure other language plurality is
		// same as English?
		if (p.totalSelected > 1) {
			errandText = "errands";
		} else {
			errandText = "errand";
		}
		let selectedString =  I("{ERRAND_TEXT} selected.")
				.replace('{ERRAND_TEXT}', errandText)
			, totalNumberString =  p.totalSelected + " "
			, disabledClass = p.agentWorking ? " disabled" : ""
			;
		let showCloseButton = true;
		if(allowCloseErrand == false && this.hasEmailService() == true){
			showCloseButton = false;
		}
		return (
			<div className="errand-list-view-actions" hidden={p.hidden}>
				<div>
					<span className="title total-selected">{totalNumberString}</span>
					<span className="title">{selectedString}</span>
				</div>
				<div>
					<span className="title-actions">{I("Choose an action:")}</span>
					<SidebarSettingsList className="list-actions">
						{!p.hasChatErrand && !closed && p.showHighPriorityFeature &&
							<WrapRed onClick={this.onSetPriority} data-qa-id="wf-action-high-priority" customClass={disabledClass}>
								<Icon className="icon-caution" />
								<span>{I("Set high priority")}</span>
							</WrapRed>
						}
						{!p.hasChatErrand && !closed && this.renderDueDateOption()}
						{!p.hasChatErrand && !closed && this.renderSelectToMoveFolder()}
						{!p.hasChatErrand && !closed && p.currentContext != POSTPONED_ERRANDS && this.renderSelectToForward()}
						<ForwardErrandsToAgent
							allowAgentForwardToAgent={allowAgentForwardToAgent}
							data={forwardErrandsAgents}
							hidden={p.hasChatErrand || closed || !allowForwardErrandsToAgent}
							loading={isLoadingFwdErrandsAgents}
							onOpen={this.handleOpenForwardToAgent}
							onSelect={this.handleSelectForwardToAgent}
							agentWorking={p.agentWorking}
						/>
						{features['link-errand'] && !p.hasChatErrand && (p.currentContext == MY_ERRANDS || p.myErrandsOnly) &&
							<WrapDarkGrey onClick={this.handleLinkErrand} data-qa-id="wf-action-link" customClass={disabledClass}>
								<Icon className="fa fa-link" />
								<HotkeyLabel
									id={"wf-action-link"}
									text={I("Link errands")}
									hotkey={workflowHotkeyChar(RETURN_ERRAND)}
								/>
							</WrapDarkGrey>
						}
						{!p.hasChatErrand && (p.currentContext == MY_ERRANDS || p.currentContext == POSTPONED_ERRANDS || p.myErrandsOnly) &&
							p.agentCanPutBackErrands &&
							<WrapRed onClick={this.handleReturnToInbox} data-qa-id="wf-action-return" customClass={disabledClass}>
								<Icon className="fa fa-undo" />
								<HotkeyLabel
									id={"wf-action-return"}
									text={I("Return errand")}
									hotkey={workflowHotkeyChar(RETURN_ERRAND)}
								/>
							</WrapRed>
						}
						{features['sending-bulk-errand'] && !closed && !p.hasChatErrand &&
							<WrapDarkGrey onClick={this.handleSendingBulkErrand} data-qa-id="wf-action-link" customClass={disabledClass}>
								<Icon className="fa fa-send-o" />
								<HotkeyLabel
									id={"wf-action-link"}
									text={I("Send errands")}
									hotkey={workflowHotkeyChar(SEND_ERRAND)}
								/>
							</WrapDarkGrey>
						}
						<WrapWhite onClick={this.onPrintErrands} data-qa-id="wf-action-print">
							<Icon className="icon-printer" />
							{
								p.currentOpened === 0 &&
									<ActionModalPrintCtnr
										onToggle={this.onPrintErrands}
										onPrintAction={this.printIt}
									/>
							}
							<span>{I("Print preview")}</span>
						</WrapWhite>
						{!p.hasChatErrand && !closed && p.agentCanDeleteErrandsFeature &&
							<WrapWhite onClick={this.onDeleteErrands} data-qa-id="wf-action-delete-errand" customClass={disabledClass}>
								<Icon className="icon-trash" />
								<HotkeyLabel
									id={"wf-action-delete"}
									text={I("Delete")}
									hotkey={workflowHotkeyChar(DELETE_SELECTED)}
								/>
							</WrapWhite>
						}
						{!closed && showCloseButton &&
							<WrapWhite onClick={this.onCloseErrands} data-qa-id="wf-action-close" customClass={disabledClass}>
								<Icon className="fa fa-times" />
								<HotkeyLabel
									id={"wf-action-close"}
									text={I("Close")}
									hotkey={workflowHotkeyChar(CLOSE_SELECTED)}
								/>
							</WrapWhite>
						}
						{!p.hasChatErrand && !closed && (p.currentContext == NEW_ERRANDS ||
							((p.currentContext == MY_ERRANDS || p.currentContext == SEARCH_ERRANDS) && p.selectedAgent !== 0)) &&
							<WrapBlue onClick={this.onAcquireErrands} data-qa-id="wf-action-acquire" customClass={disabledClass+" acquire"}>
								<Icon className="fas fa-plus"/>
								<HotkeyLabel
									id={"wf-action-acquire"}
									text={I("Acquire")}
								/>
							</WrapBlue>
						}
					</SidebarSettingsList>
				</div>
			</div>
		);
	}
}
